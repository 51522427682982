.app__carousel {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__carousel-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  
}

.app__carousel-slider {
  width: 320px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 0.5rem;
  }
  p {
    margin: 2rem 0 4rem;
  }

  @media screen and (min-width: 900px){
    width: 700px;
    
    img {
      height: 650px;
    }
  }
  @media screen and (min-width: 2000px){
      width: 900px;
      margin: 2rem 4rem;

      img {
        height: 900px;
      }
  }

}
